<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯注册码" prop="elevatornum" style="width: 22%">
              <a-input v-model.trim="queryParams.elevatornum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="区域" prop="areacode" style="width: 22%">
              <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="地址" prop="address" style="width: 22%">
              <a-input v-model.trim="queryParams.address" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯救援码" prop="elevatorrescue" style="width: 22%">
              <a-input v-model.trim="queryParams.elevatorrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="使用单位" prop="userdep" style="width: 22%">
              <a-input v-model="queryParams.userdep" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="维保单位" prop="maintenancedep" style="width: 22%">
              <a-input v-model="queryParams.maintenancedep" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安装日期" prop="installendtime" style="width: 22%">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="elevatorid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.elevatorid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.elevatorid">修改</a-menu-item>
                <a-menu-item v-show="userInfo.usertype=='0'" :key="'transform-'+record.elevatorid">转化</a-menu-item>
                <a-menu-item :key="'delete-'+record.elevatorid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-quick-jumper show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>

<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import { mapState } from 'vuex'
import moment from 'moment'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {deleteElevatorInfo, getElevatorInfoListByCondition} from "A/jcgn";
import addOrEditModal from "V/jcgn/elevatorData/addOrEditModal";
export default {
  name: 'elevatorData',
  mixins: [areaselect, deptselect, pagination],
  components: {
    addOrEditModal,
  },
  props: ['pageType'],
  data() {
    return {
      moment,
      showAdvanced: false,
      queryParams: {
        elevatornum: '',
        elevatorrescue: '',
        regesternum:'',
        address: '',
        areacode: '',
        installstarttime: '',
        installendtime: '',
        formalflag:'0',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      tableColumns: [
        {
          title: '电梯注册代码',
          dataIndex: 'elevatornum',
          key: 'elevatornum',
          ellipsis: true,
          width:180,
        },
        {
          title: '安装地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdep',
          key: 'userdep',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedep',
          key: 'maintenancedep',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '电梯档案(NI)';
    },
    breadcrumb() {
      return [
        {
          name: '基础功能管理',
          path: ''
        },
        {
          name: '电梯档案管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.installstarttime = start;
      this.queryParams.installendtime = end;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = null;
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getElevatorInfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.elevatorid);
        },
        onCancel() {},
      });
    },
    delete(elevatorid) {
      this.showLoading();
      if(elevatorid) {
        let params = {
          elevatorid
        };
        deleteElevatorInfo(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      this.modalDetailData = record;
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let elevatorid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'elevatorid', elevatorid);
      if(type == 'detail') {
        this.showModal('detail', elevatorid, record)
      }else if(type == 'edit') {
        this.showModal('edit', elevatorid, record)
      }else if(type == 'delete') {
        this.deleteConfirm(elevatorid, record)
      }else if(type=='transform'){
        this.showModal('transform', elevatorid, record)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>