<template>
  <a-modal v-model="modalVisible" title="所属机构" :width="660" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <span>搜索：</span><input id="tipinput" type="text" style="border: solid 1px #efefef">
    <div id="elevatorMap" @click=""></div>
  </a-modal>
</template>
<script>
export default {
  props: ['visible', 'areacode'],
  data() {
    return {
      Map:null,
      modalVisible: false,
      map: null,
      coordinate: [],
      marker:null,
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }else {
          this.$nextTick(() => {
            this.init();
          })
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    init() {
      var _this=this;
      var Map = new window.AMap.Map('elevatorMap', {
        resizeEnable: false, //是否监控地图容器尺寸变化
        zoom:10, //初始化地图层级
      });
      this.Map=Map;
      AMap.event.addListener(Map,'click',function (e) { //添加点击事件,传入对象名，事件名，回调函数
        if(_this.coordinate.length>0){
          Map.remove(_this.marker)
        }
        _this.marker = new AMap.Marker({
          position: new AMap.LngLat(e.lnglat.lng,e.lnglat.lat)
        });
        _this.Map.add(_this.marker)
        _this.coordinate = [e.lnglat.lng, e.lnglat.lat];
      })
      this.mapSearchInit()
    },
    /** 初始化搜索工具 */
    mapSearchInit(){
      //输入提示
      var autoOptions = {
        input: "tipinput"
      };
      var auto = new AMap.Autocomplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
        map: this.Map
      });  //构造地点查询类
      AMap.event.addListener(auto, "select", select);//注册监听，当选中某条记录时会触发
      function select(e) {
        placeSearch.setCity(e.poi.adcode);
        placeSearch.search(e.poi.name);  //关键字查询查询
      }
      AMap.event.addListener(placeSearch, "markerClick",markerClick);
      var _this=this;
      function markerClick(e) {
        _this.coordinate = [e.data.location.lng, e.data.location.lat];
      }
    },
    confirm() {
      if(this.coordinate) {
        this.$emit('get-coordinate', this.coordinate.map(item => item.toFixed(4)));
        this.modalVisible = false;
      }else {
        this.$message.info('请选择地图中一个位置');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#elevatorMap{
  height: 400px;
  margin-top: 5px;
}
</style>