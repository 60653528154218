<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm()">提交</a-button>
        <a-button v-if="userInfo.usertype=='0'&&showType=='transform'" type="primary" @click="confirm()">转为正式数据</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-tabs type="card" v-model="activeKey">
          <a-tab-pane key="1" tab="基础/设备信息"></a-tab-pane>
          <a-tab-pane key="2" tab="管理信息"></a-tab-pane>
          <a-tab-pane key="3" tab="设备安装信息"></a-tab-pane>
        </a-tabs>
        <div v-show="activeKey == '1'">
          <!-- ==========================基础信息======================= -->
          <h3 style="font-weight: bold; color: #333;">基础信息</h3>
          <a-form-model-item label="电梯注册码" prop="elevatornum">
            <a-input v-model.trim="formDatas.elevatornum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="救援码" prop="elevatorrescue">
            <a-input v-model.trim="formDatas.elevatorrescue" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="电梯追溯码" prop="traceabilitynum">
            <a-input v-model.trim="formDatas.traceabilitynum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="设备代码" prop="devicecode">
            <a-input v-model.trim="formDatas.devicecode" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="注册登记机构" prop="registerdep">
            <a-input v-model.trim="formDatas.registerdep" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="注册登记时间" prop="registertime">
            <a-date-picker v-model="formDatas.registertime" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="注册更新时间" prop="registeruptime">
            <a-date-picker v-model="registeruptimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="内部编号" prop="elevatorcode">
            <a-input v-model.trim="formDatas.elevatorcode" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="出厂编号" prop="factorynum">
            <a-input v-model.trim="formDatas.factorynum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="厂车牌照编号" prop="factorylicense">
            <a-input v-model.trim="formDatas.factorylicense" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="操作人员" prop="operator">
            <a-input v-model.trim="formDatas.operator" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="区域" prop="areacode">
            <a-cascader :disabled="showType=='detail'" :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="areaCascaderSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true" :allow-clear="false"></a-cascader>
          </a-form-model-item>
          <a-form-model-item label="安装地址" prop="address">
            <a-input v-model.trim="formDatas.address" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="坐标" prop="coordinatex">
            <a-input v-model.trim="formDatas.coordinatex" style="width: 30%" :disabled="showType=='detail'"/>
            <a-input v-model.trim="formDatas.coordinatey" style="width: 30%; margin-left: 5%" :disabled="showType=='detail'"/>
            <a-button type="primary" style="width: 30%; margin-left: 5%" @click="selectCoordinateVisible=true" v-if="showType=='edit'||showType=='transform'">选择</a-button>
          </a-form-model-item>
          <a-form-model-item label="使用状态" prop="status">
            <a-radio-group v-model="formDatas.status" :disabled="showType=='detail'">
              <a-radio :value="1">使用</a-radio>
              <a-radio :value="0">未使用</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="使用场所" prop="place">
            <a-input v-model="formDatas.place" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="电梯重要等级" prop="level">
            <a-select v-model="formDatas.level" :disabled="showType=='detail'">
              <a-select-option v-for="(item, index) in levelOptions" :key="index" :value="item.code">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- =======================设备信息======================= -->
          <h3 style="font-weight: bold; color: #333;">设备信息</h3>
          <a-form-model-item label="用户设备类别" prop="devicetype">
            <a-input v-model="formDatas.devicetype" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="用户设备型号" prop="devicemodel">
            <a-input v-model="formDatas.devicemodel" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="电梯品牌" prop="brandname">
            <a-input v-model="formDatas.brandname" :disabled="showType=='detail'"></a-input>
          </a-form-model-item>
          <a-form-model-item label="设备许可证" prop="devicelicense">
            <a-input v-model.trim="formDatas.devicelicense" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="许可证有效期" prop="devicelicstarttime">
            <a-range-picker v-model="devicelictimeRange" :disabled="showType=='detail'"></a-range-picker>
          </a-form-model-item>
          <a-form-model-item label="许可证级别" prop="devicelicenselevel">
            <a-input v-model.trim="formDatas.devicelicenselevel" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="拖动方式" prop="dragtype">
            <a-input v-model.trim="formDatas.dragtype" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="最高楼层" prop="floornum">
            <a-input v-model.trim="formDatas.floornum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="层数" prop="floorcount">
            <a-input v-model.trim="formDatas.floorcount" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="站数" prop="stepnum">
            <a-input v-model.trim="formDatas.stepnum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="门数" prop="doornum">
            <a-input v-model.trim="formDatas.doornum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="额定速度" prop="speed">
            <a-input v-model.trim="formDatas.speed" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="载重量(kg)" prop="weight">
            <a-input v-model.trim="formDatas.weight" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">设备使用标志和登记信息证件查看</h3>
          <a-form-model-item label="使用登记编号" prop="regesternum">
            <a-input v-model.trim="formDatas.regesternum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="使用标志证" prop="userflagurl">
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="userflagurlList" @change="userflagurlUploaded">
              <a-button :disabled="showType=='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="使用登记证" prop="userloginurl">
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="userloginurlList" @change="userloginurlUploaded">
              <a-button :disabled="showType=='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '2'">
          <!-- ========================使用单位信息============== -->
          <h3 style="font-weight: bold; color: #333;">使用单位信息</h3>
          <a-form-model-item label="使用单位" prop="userdep">
            <a-input v-model="formDatas.userdep" :disabled="showType=='detail'"></a-input>
          </a-form-model-item>
          <a-form-model-item label="使用单位代码" prop="userdepid_depnum">
            <span>{{formDatas.userdepid_depnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="营业执照代码" prop="userdepid_businessnum">
            <span>{{formDatas.userdepid_businessnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="使用单位地址" prop="userdepid_address">
            <span>{{formDatas.userdepid_address}}</span>
          </a-form-model-item>
          <a-form-model-item label="物业公司所属品牌" prop="userdepid_elevatorpropertyname">
            <span>{{formDatas.userdepid_elevatorpropertyname}}</span>
          </a-form-model-item>
          <a-form-model-item label="邮政编码" prop="userdepid_mailcode">
            <span>{{formDatas.userdepid_mailcode}}</span>
          </a-form-model-item>
          <a-form-model-item label="安全管理员" prop="safeperson">
            <a-input v-model.trim="formDatas.safeperson" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="安全管理员电话" prop="safephone">
            <a-input v-model.trim="formDatas.safephone" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="userdeppreson">
            <a-input v-model.trim="formDatas.userdeppreson" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="负责人电话" prop="userdeppersonphone">
            <a-input v-model.trim="formDatas.userdeppersonphone" :max-length="11" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="小区所属地产品牌" prop="estatemodel">
            <a-input v-model.trim="formDatas.estatemodel" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <!-- =======================维保单位信息====================== -->
          <h3 style="font-weight: bold; color: #333;">维保单位信息</h3>
          <a-form-model-item label="维保单位" prop="maintenancedep">
            <a-input v-model="formDatas.maintenancedep" :disabled="showType=='detail'"></a-input>
          </a-form-model-item>
          <a-form-model-item label="维保单位代码" prop="maintenancedepid_depnum">
            <span>{{formDatas.maintenancedepid_depnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="资质证书代码" prop="maintenancedepid_certificatenum">
            <span>{{formDatas.maintenancedepid_certificatenum}}</span>
          </a-form-model-item>
          <a-form-model-item label="营业执照代码" prop="maintenancedepid_businessnum">
            <span>{{formDatas.maintenancedepid_businessnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="电梯安装许可证编码" prop="maintenancedepid_licensenum">
            <span>{{formDatas.maintenancedepid_licensenum}}</span>
          </a-form-model-item>
          <a-form-model-item label="单位地址" prop="maintenancedepid_address">
            <span>{{formDatas.maintenancedepid_address}}</span>
          </a-form-model-item>
          <a-form-model-item label="救援人员" prop="rescueperson">
            <span>{{formDatas.rescueperson}}</span>
          </a-form-model-item>
          <a-form-model-item label="救援人员电话" prop="rescuephone">
            <span>{{formDatas.rescuephone}}</span>
          </a-form-model-item>
          <a-form-model-item label="区域经理" prop="maintenancedepid_areamanager">
            <span>{{formDatas.maintenancedepid_areamanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="区域经理电话" prop="maintenancedepid_areamanagerphone">
            <span>{{formDatas.maintenancedepid_areamanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="单位经理" prop="maintenancedepid_depmanager">
            <span>{{formDatas.maintenancedepid_depmanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="单位经理电话" prop="maintenancedepid_depmanagerphone">
            <span>{{formDatas.maintenancedepid_depmanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="邮政编码" prop="maintenancedepid_mailcode">
            <span>{{formDatas.maintenancedepid_mailcode}}</span>
          </a-form-model-item>
          <a-form-model-item label="大修/改造日期" prop="bigrepairtime">
            <a-date-picker v-model="bigrepairtimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="大修周期(周)" prop="bigrepaircycle">
            <a-input v-model.trim="formDatas.bigrepaircycle" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <!-- =======================产权单位信息======================= -->
          <h3 style="font-weight: bold; color: #333;">产权单位信息</h3>
          <a-form-model-item label="产权单位名称" prop="propertydep">
            <a-input v-model.trim="formDatas.propertydep" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="产权单位代码" prop="propertydepnum">
            <a-input v-model.trim="formDatas.propertydepnum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="产权单位地址" prop="propertydepadr">
            <a-input v-model.trim="formDatas.propertydepadr" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="邮政编码" prop="propertydeppost">
            <a-input v-model.trim="formDatas.propertydeppost" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="产权单位法人" prop="propertydepperson">
            <a-input v-model.trim="formDatas.propertydepperson" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="产权单位负责人" prop="propertydepadmin">
            <a-input v-model.trim="formDatas.propertydepadmin" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="电话" prop="propertydepphone">
            <a-input v-model.trim="formDatas.propertydepphone" :max-length="12" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="安装日期" prop="installtime">
            <a-date-picker v-model="installtimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="使用日期" prop="usetime">
            <a-date-picker v-model="usetimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
          <!-- =====================应急处置单位==================== -->
          <h3 style="font-weight: bold; color: #333;">应急处置单位</h3>
          <a-form-model-item label="应急处置单位" prop="rescuedep">
            <a-input v-model="formDatas.rescuedep" :disabled="showType=='detail'"></a-input>
          </a-form-model-item>
          <a-form-model-item label="二级救援站电话" prop="testexamineperson">
            <span>{{formDatas.testexamineperson}}</span>
          </a-form-model-item>
          <a-form-model-item label="驻站人一" prop="stationperson1">
            <span>{{formDatas.stationperson1}}</span>
          </a-form-model-item>
          <a-form-model-item label="驻站人一电话" prop="stationphone1">
            <span>{{formDatas.stationphone1}}</span>
          </a-form-model-item>
          <a-form-model-item label="驻站人二" prop="stationperson2">
            <span>{{formDatas.stationperson2}}</span>
          </a-form-model-item>
          <a-form-model-item label="驻站人二电话" prop="stationphone2">
            <span>{{formDatas.stationphone2}}</span>
          </a-form-model-item>
          <a-form-model-item label="二级救援站负责人" prop="pristationperson">
            <span>{{formDatas.pristationperson}}</span>
          </a-form-model-item>
          <a-form-model-item label="二级救援站负责人电话" prop="pristationperphone">
            <span>{{formDatas.pristationperphone}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">维保合同信息</h3>
          <a-form-model-item label="合同上传地址" prop="maintenancecontacturl">
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="maintenancecontacturlList" @change="maintenancecontacturlUploaded">
              <a-button :disabled="showType=='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="合同有效期" prop="maintenancecontacttime">
            <a-date-picker v-model="maintenancecontacttimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">保险信息</h3>
          <a-form-model-item label="保险公司名称" prop="insurancedep">
            <a-input v-model.trim="formDatas.insurancedep" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="保险种类" prop="insurancetype">
            <a-input v-model.trim="formDatas.insurancetype" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="保险单号" prop="insurancenum">
            <a-input v-model.trim="formDatas.insurancenum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="维修资金使用情况" prop="fundsused">
            <a-input v-model.trim="formDatas.fundsused" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="理赔电话" prop="insurancephone">
            <a-input v-model.trim="formDatas.insurancephone" :max-length="12" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="理赔有效期" prop="insuranceexpiretime">
            <a-date-picker v-model="insuranceexpiretimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '3'">
          <h3 style="font-weight: bold; color: #333;">设备安装信息</h3>
          <a-form-model-item label="安装单位" prop="installdep">
            <a-input v-model.trim="formDatas.installdep" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="安装单位编码" prop="installdepnum">
            <a-input v-model.trim="formDatas.installdepnum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="安装证书编码" prop="installlicnum">
            <a-input v-model.trim="formDatas.installlicnum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="安装负责人" prop="installadmin">
            <a-input v-model.trim="formDatas.installadmin" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="安装负责人电话" prop="installadminphone">
            <a-input v-model.trim="formDatas.installadminphone" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="安装施工单位" prop="constructiondep">
            <a-input v-model.trim="formDatas.constructiondep" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="施工日期" prop="constructiontime">
            <a-date-picker v-model="constructiontimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="土建验收单位" prop="acceptdep">
            <a-input v-model.trim="formDatas.acceptdep" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="审核单位" prop="checkunit">
            <a-input v-model.trim="formDatas.checkunit" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="审核日期" prop="checktime">
            <a-date-picker v-model="checktimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="验收报告编号" prop="checkreportnum">
            <a-input v-model.trim="formDatas.checkreportnum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">设计制造信息</h3>
          <a-form-model-item label="设计单位" prop="designdep">
            <a-input v-model.trim="formDatas.designdep" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="设计单位编码" prop="designdepnum">
            <a-input v-model.trim="formDatas.designdepnum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="设备制造商" prop="manufacturerid">
            <a-input v-model="formDatas.manufacturerdep" :disabled="showType=='detail'"></a-input>
          </a-form-model-item>
          <a-form-model-item label="资格证" prop="manufacturerlic">
            <a-input v-model.trim="formDatas.manufacturerlic" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="生产许可证" prop="productlic">
            <a-input v-model.trim="formDatas.productlic" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="生产编号" prop="productnum">
            <a-input v-model.trim="formDatas.productnum" :disabled="showType=='detail'"/>
          </a-form-model-item>
          <a-form-model-item label="生产日期" prop="producttime">
            <a-date-picker v-model="producttimeMoment" style="width: 100%;" :disabled="showType=='detail'"></a-date-picker>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
    <select-coordinate :visible.sync="selectCoordinateVisible" :areacode="formDatas.areacode" @get-coordinate="getCoordinate"></select-coordinate>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { getTimeRange } from 'U'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import SelectCoordinate from 'C/modals/SelectCoordinate.vue'
import {
  getElevatorInfoById,
  addElevatorInfo,
  modifyElevatorInfo, transformLiftInfo,
} from 'A/jcgn'
import brandSelect from "C/modals/brandSelect";
import deviceProducerSelect from "C/modals/deviceProducerSelect";
export default {
  mixins: [areaselect, deptselect],
  components: {
    SelectCoordinate,
    brandSelect,
    deviceProducerSelect
  },
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      size: 'default',
      phoneLists:[],
      brandselectVisible:false,
      ManufacturerselectVisible:false,
      selectCoordinateVisible: false,
      selectDevicemodelVisible: false,
      selectcommunityidmodelVisible: false,
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      activeKey: '1',
      formDatas: {
        // 基础信息
        elevatorid: '',
        elevatornum: '',
        elevatorrescue: '',
        traceabilitynum: '',
        devicecode: '',
        registerdep: '',
        registertime: '',
        registeruptime: '',
        elevatorcode: '',
        factorynum: '',
        factorylicense: '',
        operator: '',
        areacode: '',
        address: '',
        coordinatex: '',
        coordinatey: '',
        status: 1,//0-未使用，1-使用
        place: '',
        level: '',
        // 设备信息
        devicetype: '',
        devicemodelid: '',
        devicemodel:'',
        devicelicense: '',
        devicelicstarttime: '',
        devicelicendtime: '',
        devicelicenselevel: '',
        dragtype: '',
        floornum: '',
        floorcount: '',
        stepnum: '',
        doornum: '',
        speed: '',
        weight: '',
        // 设备使用标志和登记信息证件查看
        regesternum: '',
        userflagurl: '',
        userloginurl: '',
        // 使用单位信息
        userdep: '',
        estatemodel:'',
        // 产权单位信息
        propertydep: '',
        propertydepnum: '',
        propertydepadr: '',
        propertydeppost: '',
        propertydepperson: '',
        propertydepadmin: '',
        propertydepphone: '',
        installtime: '',
        usetime: '',
        // 设备维保信息
        maintenancedep: '',
        // 检验信息
        bigrepairtime: '',
        bigrepaircycle: '',
        // 维保合同信息
        maintenancecontacturl: '',
        maintenancecontacttime: '',
        // 保险信息
        insurancedep: '',
        insurancenum: '',
        insurancephone: '',
        insuranceexpiretime: '',
        insurancetype:'',
        fundsused:'',
        // 设备安装信息
        installdep: '',
        installdepnum: '',
        installlicnum: '',
        installadminphone: '',
        installadmin: '',
        constructiondep: '',
        constructiontime: '',
        acceptdep: '',
        checkunit: '',
        checktime: '',
        checkreportnum: '',
        // 设计制造信息
        designdep: '',
        designdepnum: '',
        manufacturername:'',
        manufacturerlic: '',
        productlic: '',
        productnum: '',
        producttime: '',
      },
      //这里面的数据属于必填项
      formRules: {
        elevatornum: [{required: false, message: '请输入电梯注册码'}],
        elevatorrescue: [{required: false, message: '请输入救援码'}],
        areacode: [{required: true, message: '请选择区域'}],
        address: [{required: true, message: '请输入安装地址'}],
        coordinatex: [{required: true, message: '请选择电梯位置坐标'}],
        place: [{required: true, message: '请选择使用场合'}],
        level: [{required: true, message: '请选择电梯重要等级'}],
        devicetype: [{required: true, message: '请选择设备类别'}],
        userdep: [{required: true, message: '请选择使用单位'}],
      },
      registertimeMoment: null,
      registeruptimeMoment: null,
      placeidOptions: [],
      levelOptions: [
        {
          code: 0,
          name: '极高'
        },
        {
          code: 1,
          name: '高'
        },
        {
          code: 2,
          name: '一般'
        },
      ],
      dataSource:[],
      devicelictimeRange: null,
      installtimeMoment: null,
      usetimeMoment: null,
      bigrepairtimeMoment: null,
      insuranceexpiretimeMoment: null,
      constructiontimeMoment: null,
      checktimeMoment: null,
      producttimeMoment: null,
      maintenancecontacttimeMoment: null,
      manufactureridOptions: [],
      userflagurlList: [],
      userloginurlList: [],
      maintenancecontacturlList: [],
      showDeviceTable: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else if(this.showType == 'transform') {
        return '转化'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetDatas();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    registertimeMoment(val) {
      if(val) {
        this.formDatas.registertime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('registertime')
      }else {
        this.formDatas.registertime = '';
      }
    },
    registeruptimeMoment(val) {
      if(val) {
        this.formDatas.registeruptime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('registeruptime')
      }else {
        this.formDatas.registeruptime = '';
      }
    },
    areaCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.areacode = val[val.length-1];
        this.$refs.modalForm.clearValidate('areacode')
      }else {
        this.formDatas.areacode = '';
      }
    },
    devicelictimeRange(val) {
      let {start, end} = getTimeRange(val);
      this.formDatas.devicelicstarttime = start;
      this.formDatas.devicelicendtime = end;
    },
    installtimeMoment(val) {
      if(val) {
        this.formDatas.installtime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('installtime')
      }else {
        this.formDatas.installtime = '';
      }
    },
    usetimeMoment(val) {
      if(val) {
        this.formDatas.usetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('usetime')
      }else {
        this.formDatas.usetime = '';
      }
    },
    bigrepairtimeMoment(val) {
      if(val) {
        this.formDatas.bigrepairtime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('bigrepairtime')
      }else {
        this.formDatas.bigrepairtime = '';
      }
    },
    insuranceexpiretimeMoment(val) {
      if(val) {
        this.formDatas.insuranceexpiretime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('insuranceexpiretime')
      }else {
        this.formDatas.insuranceexpiretime = '';
      }
    },
    constructiontimeMoment(val) {
      if(val) {
        this.formDatas.constructiontime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('constructiontime')
      }else {
        this.formDatas.constructiontime = '';
      }
    },
    checktimeMoment(val) {
      if(val) {
        this.formDatas.checktime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('checktime')
      }else {
        this.formDatas.checktime = '';
      }
    },
    producttimeMoment(val) {
      if(val) {
        this.formDatas.producttime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('producttime')
      }else {
        this.formDatas.producttime = '';
      }
    },
    maintenancecontacttimeMoment(val) {
      if(val) {
        this.formDatas.maintenancecontacttime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('maintenancecontacttime')
      }else {
        this.formDatas.maintenancecontacttime = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
  },
  methods: {
    initDetail() {
      if (this.detailData && this.detailData.elevatorid && (this.showType == 'edit' || this.showType == 'detail' || this.showType == 'transform')) {
        this.$nextTick(() => {
          let params = {
            elevatorid: this.detailData.elevatorid
          }
          getElevatorInfoById(params).then(res => {
            if (res && res.returncode == '0') {
              for (let key in this.formDatas) {
                if (res.item[key] || res.item[key] === 0) {
                  this.formDatas[key] = res.item[key];
                }
              }
              this.formDatas.userdep=res.item.userdep
              this.formDatas.maintenancedep=res.item.maintenancedep
              this.formDatas.manufacturerdep=res.item.manufacturerdep
              this.formDatas.rescuedep=res.item.rescuedep
              this.formDatas.testdep=res.item.testdep
              // 特别数据回显
              if (res.item.registertime) {
                this.registertimeMoment = moment(res.item.registertime, 'YYYYMMDD')
              }
              if (res.item.registeruptime) {
                this.registeruptimeMoment = moment(res.item.registeruptime, 'YYYYMMDD')
              }
              if (res.item.areacode) {
                this.initAreaOptionsByAreacode(res.item.areacode)
              }
              if (res.item.registerdep) {
                this.initDeptOptionsByUserdepid(res.item.registerdep, 'registerdepCascaderSelected', this.userInfo.usertype != '2')
              }
              if (res.item.devicelicstarttime && res.item.devicelicendtime) {
                this.devicelictimeRange = [moment(res.item.devicelicstarttime, 'YYYYMMDD'), moment(res.item.devicelicendtime, 'YYYYMMDD')]
              }
              if (res.item.installtime) {
                this.installtimeMoment = moment(res.item.installtime, 'YYYYMMDD')
              }
              if (res.item.usetime) {
                this.usetimeMoment = moment(res.item.usetime, 'YYYYMMDD')
              }
              if (res.item.bigrepairtime) {
                this.bigrepairtimeMoment = moment(res.item.bigrepairtime, 'YYYYMMDD')
              }
              if (res.item.insuranceexpiretime) {
                this.insuranceexpiretimeMoment = moment(res.item.insuranceexpiretime, 'YYYYMMDD')
              }
              if (res.item.constructiontime) {
                this.constructiontimeMoment = moment(res.item.constructiontime, 'YYYYMMDD')
              }
              if (res.item.checktime) {
                this.checktimeMoment = moment(res.item.checktime, 'YYYYMMDD')
              }
              if (res.item.producttime) {
                this.producttimeMoment = moment(res.item.producttime, 'YYYYMMDD')
              }
              if (res.item.maintenancecontacttime) {
                this.maintenancecontacttimeMoment = moment(res.item.maintenancecontacttime, 'YYYYMMDD')
              }
              if (res.item.userflagurl) {
                let nameIndex = res.item.userflagurl.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = res.item.userflagurl.lastIndexOf('\\');
                }
                let fileName = res.item.userflagurl.substr(nameIndex + 1);
                this.userflagurlList = [{
                  uid: 'userflagurluid',
                  name: fileName,
                  status: 'done',
                  url: res.item.userflagurl,
                }];
              }
              if (res.item.userloginurl) {
                let nameIndex = res.item.userloginurl.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = res.item.userloginurl.lastIndexOf('\\');
                }
                let fileName = res.item.userloginurl.substr(nameIndex + 1);
                this.userloginurlList = [{
                  uid: 'userloginurluid',
                  name: fileName,
                  status: 'done',
                  url: res.item.userloginurl,
                }];
              }
              if (res.item.maintenancecontacturl) {
                let nameIndex = res.item.maintenancecontacturl.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = res.item.maintenancecontacturl.lastIndexOf('\\');
                }
                let fileName = res.item.maintenancecontacturl.substr(nameIndex + 1);
                this.maintenancecontacturlList = [{
                  uid: 'maintenancecontacturluid',
                  name: fileName,
                  status: 'done',
                  url: res.item.maintenancecontacturl,
                }];
              }
            }
          })
        })
      }
    },
    resetDatas() {
      this.formDatas.elevatorid = '';
      this.registertimeMoment = null;
      this.registeruptimeMoment = null;
      this.examinetimeMoment = null;
      this.nextexaminetimeMoment = null;
      this.testexaminetimeMoment = null;
      this.testnextexaminetimeMoment = null;
      this.areaCascaderSelected = [];
      this.devicelictimeRange = null;
      this.installtimeMoment = null;
      this.usetimeMoment = null;
      this.bigrepairtimeMoment = null;
      this.insuranceexpiretimeMoment = null;
      this.constructiontimeMoment = null;
      this.checktimeMoment = null;
      this.producttimeMoment = null;
      this.maintenancecontacttimeMoment = null;
      this.userflagurlList = [];
      this.userloginurlList = [];
      this.maintenancecontacturlList = [];
      if (this.$refs.modalForm) {
        this.$refs.modalForm.clearValidate();
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit' || this.showType == 'transform') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            let params = {
              ...this.formDatas,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addElevatorInfo(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyElevatorInfo(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else if(this.showType == 'transform'){
              transformLiftInfo(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    getCoordinate(coordinate) {
      this.formDatas.coordinatex = coordinate[0];
      this.formDatas.coordinatey = coordinate[1];
    },
    userflagurlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.userflagurl = file.response.urlPath;
        }
        return file;
      });

      this.userflagurlList = fileList;
    },
    userloginurlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.userloginurl = file.response.urlPath;
        }
        return file;
      });

      this.userloginurlList = fileList;
    },
    maintenancecontacturlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.maintenancecontacturl = file.response.urlPath;
        }
        return file;
      });

      this.maintenancecontacturlList = fileList;
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error('上传的文件类型只能是.jpg或者.png图片类型!');
        return false
      }
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
        return false
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>